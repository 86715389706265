/*-----------------------------
ヒーロー
-----------------------------*/
.blog_hero {
  background-image: url(img/blog_hero.jpg);
  background-size: cover;
  background-position: 20% 20%;
}
/*-----------------------------
作品の最新一覧
-----------------------------*/
.archive_layout {
  margin-bottom: 150px;
}
.archive {
  margin-top: 150px;
}
.archive_list {
  border-bottom: 1px solid $color-pink;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .archive {
    margin-top: 100px;
  }
}
.archive a {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.archive_kiji_title {
    font-size: 1.3rem;
}

.archive_list a {color: $color-pink;}

.archive_cat {
  a {
      width:200px;
      text-align: center;
      padding: 0 1rem;
      font-size: 12px;
      background: #ccc;
      display: inline-block;
      color: #fff;
      border-radius: 100px;
      margin-top: 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      &:hover {
        background: $color-hover;
        text-decoration: none;
      }
  }
}
.archive_time {
    display: inline;
}

/*-----------------------------
ページネーション
-----------------------------*/
.page-area {
    position: relative;
    width: 100%;
    height: 2rem;
}
.pagination{
    color: $color-pink;
    line-height:2em;
    text-align:center;
    clear: both;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
}
.pagination a{
    text-decoration:none;
    padding: 0.25rem 0.7rem;
    margin-left: 0.5rem;
    border: 1px solid $color-pink;
    color: $color-pink;
    &:hover {
      background: $color-pink;
      color: #fff;
    }
}
.pagination span {
  padding: 0.25rem 0.7rem;
  margin-left: 0.5rem;
  border: 1px solid $color-pink;
  color: #fff;
  background-color: $color-pink;
}

/*-----------------------------
サイドバー
-----------------------------*/
.sidebar_category {
  list-style: none;
}
