/*-----------------------------
ヒーロー
-----------------------------*/
.profile_hero {
  background-image: url(img/archive_hero.jpg);
  background-size: cover;
  background-position: center;
}
/*-----------------------------
記事の写真
-----------------------------*/
.single_kiji {
  width: 70%;
  margin: 0 auto;
  img {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
}
.single_contents {
  margin-bottom: 2rem;
}
/*-----------------------------
ページネーション
-----------------------------*/
.post-navigation {
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: $color-gray;
      &:hover {
        color: $color-pink;
      }
    }
}

/*-----------------------------
本文
-----------------------------*/
div.single_contents p {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 1.8rem;
}

div.single_contents h1 {
    font-size: 24px;
    letter-spacing: 0.1rem;
    line-height: 3rem;
}

div.single_contents h2 {
    margin: 0;
    color: inherit;
    font-size: 22px;
    font-family: inherit;
    letter-spacing: 0.1rem;
    line-height: 2.8rem;
}

div.single_contents h2:before {
    display: none;
    content: "";
}
div.single_contents h3 {
  font-size: 20px;
  font-family: inherit;
  letter-spacing: 0.1rem;
  line-height: 2.4rem;
  color: inherit;
  text-align: inherit;
  margin: 0;
}
div.single_contents h4 {
  font-size: 18px;
  line-height: 2.2rem;
  letter-spacing: 0.1rem;
}
div.single_contents h5 {
  font-size: 16px;
  line-height: 2rem;
  letter-spacing: 0.1rem;
}
div.single_contents h6 {
  font-size: 14px;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
}
div.single_contents strong {
  font-weight: bold;
}
div.single_contents em {
  font-style: italic;
}
div.single_contents li{
  margin: 4px 0;
  &:before {
    content: "・";
  }
}
