/*-----------------------------
ファーストビュー
-----------------------------*/
.top_hero {
  width: 80%;
  margin: 0 auto;
  height: 500px;
  margin-top: 150px;
  background-image: url(img/top_hero.jpg);
  background-position: center center;
  background-size: cover;
  position: relative;
}
.top_firstview {
  position: absolute;
  bottom: 20%;
  left: 10%;
  z-index: 999;
  .top_firstview_copy {
    color: $color-pink;
    text-align: center;
    font-size: 40px;
    text-shadow: 0 0 2px #fff;
    letter-spacing: 0.5rem;
    line-height: 4rem;
  }
}
.mark1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}
.mark2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
}
.mark3 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
}
.mark4 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}
@media (max-width: 768px) {
.top_hero {
  height: 30%;
}
.top_firstview .top_firstview_copy {
  font-size: 20px;
  line-height: 2rem;
}
.mark1,.mark2,.mark3,.mark4 {
  width: 60px;
}
}
@media (max-width: 640px) {
.top_firstview {
  .top_firstview_copy {
    font-size: 24px;
  }
}
}
/*-----------------------------
コンセプト
-----------------------------*/
.top_concept {
  text-align: center;
  img {
    max-width: 450px;
  }
  p {
    font-size: 14px;
    letter-spacing: 0.3rem;
    line-height: 2rem;
    text-align: center;
    margin-top: 50px;
  }
}
@media (max-width: 640px) {
  .top_concept {
    img {
      max-width: 90%;
    }
    p {
      width: 90%;
      margin: 0 auto;
      margin-top: 50px;
    }
}
}
/*-----------------------------
ジュエルカウンセリングとは
-----------------------------*/
.top_about {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: row-reverse;
}
.top_about_left {
  width: 50%;
  h3 {
    text-align: left;
    margin-top: 4rem;
  }
}
.top_about_right {
  position: relative;
  width: 50%;
  img {
    width: 100%;
  }
  .mark2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
  }
  .mark3 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
  }
}

@media (max-width: 1080px) {
.top_about {
  display: block;
  width: 90%;
  .top_about_left,.top_about_right {
    width: 100%;
  }
  .top_about_left {
    margin-top: 3rem;
    margin-bottom: 3rem;
    h3 {
      margin-top: 0;
      text-align: center;
    }
    .btn {
      text-align: center;
    }
  }
}
.top_about_right {
  text-align: center;
  img {
    width: 100%;
  }
  .mark2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
  }
  .mark3 {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
  }
}
}
/*-----------------------------
サービス
-----------------------------*/
.top_service {
  text-align: center;
  border: 1px solid $color-pink;
  padding: 2rem 2rem;
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;
  background: #fff;
  h2 {
    margin-top: 50px;
  }
}
.top_serbice::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: -2rem;
  margin-left: 2rem;
}
@media (max-width: 768px) {
  .top_service {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.top_service ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  li {
    position: relative;
    width: 300px;
    height: 197px;
    background-image: url(img/menu_bg.png);
    background-position: center;
    background-size: cover;
    transition: 0.3s;
    &:hover {
      transform: translate(0, -20px);
      opacity: 0.7;
    }
    a {
      display: block;
      padding-top: 60px;
      color: $color-pink;
      text-decoration: none;
      font-size: 24px;
      width: 100%;
      height: 100%;
      line-height: 2.4rem;
      transition: 0.3s;
      span {
        display: block;
        font-size: 14px;
        font-family: $mincho;
      }
    }
  }
}
.top_service:after {
  content: "";
  width: 100%;
  height: 100%;
  top: 20px;
  left: 20px;
  position: absolute;
  z-index: -1;
  margin-left: 20px;
  margin-top: 20px;
  background: $color-pink;
}
@media (max-width: 1080px) {
  .top_service ul li {
    width: 200px;
    height: 131px;
    margin-top: 0px;
    a {
      padding-top: 40px;
      line-height: 2rem;
      font-size: 1rem;
    }
  }
  .top_service:after {
    top: 5px;
    left: 5px;
  }
}
@media (max-width: 768px) {
  .top_service ul {
    display: block;
    li {
      width: 260px;
      height: 167px;
      margin: 0 auto;
      margin-bottom: 3rem;
      a {
        padding-top: 60px;
      }
    }
  }
  .top_service:after {
    top: 2px;
    left: 2px;
  }
}
@media (max-width: 640px) {
  .top_service:after {
    display: none;
  }
}
/*-----------------------------
ジュエルカラーズについて
-----------------------------*/
.top_prof {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 150px;
}

.top_prof_left {
    width: 50%;
}

.top_prof_right {
    width: 50%;
    text-align: center;
    h3 {
        color: $color-gray;
        font-size: 28px;
    }
    img {
        width: 450px;
        margin-bottom: 50px;
    }
    p {
        line-height: 2rem;
        letter-spacing: 0.2rem;
        text-align: left;
        font-size: 14px;
        max-width: 450px;
        margin: 0 auto;
    }
}

.top_prof_img2 {
    margin-top: -50px;
    margin-left: 100px;
}
@media (max-width: 1080px) {
  .top_prof {
    display: block;
    .top_prof_left {
      width:70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .top_prof_img1 img {
        width: 100%;
      }
      .top_prof_img2 {
        margin-top: 0;
        margin-left: 0;
        img {
          max-width: 100%;
        }
      }
    }
    .top_prof_right {
      width: 100%;
      margin-top: 3rem;
    }
  }
}
@media (max-width: 640px) {
.top_prof_right {
  img {
    width: 100%;
  }
}
.top_prof_right p {
  max-width: 100%;
}
.top_prof .top_prof_left {
  display: block;
}
}
