/*-----------------------------
ヒーロー
-----------------------------*/
.flow_hero {
  background-image: url(img/flow_hero.jpg);
  background-size: cover;
  background-position: center;
}
/*-----------------------------
ステップ
-----------------------------*/
