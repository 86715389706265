/*-----------------------------
全体構造
-----------------------------*/
html {
  height:100%;
}
body {
  height: 100%;
  background: #fff;
  font-size: 14px;
  color: #666;
}
* {
  box-sizing: border-box;
}
section {
  margin-top: 150px;
  margin-bottom: 150px;
}
article {
  margin-top: 150px;
  margin-bottom: 150px;
}
aside {
  margin-top: 150px;
  margin-bottom: 150px;
}
@media (max-width: 768px) {
  section {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  article {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  aside {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  .column_left {
    width: 320px;
    height: 500px;
  }
  .column_right {
    width: 760px;
    padding: 0 0 0 4rem;
  }
}
@media (max-width: 1080px) {
  .column {
    display: block;
    .column_left {
      width: 100%;
      height: auto;
      padding: 0 2rem;
    }
    .column_right {
      width: 100%;
      padding: 0 2rem;
    }
  }
}
@media (max-width: 640px) {
  .column {
    .column_left {
      padding: 0;
    }
    .column_right {
      padding: 0;
    }
  }
}
/*-----------------------------
カラー
-----------------------------*/
$color-pink: #cc1a5e;
$color-hover: #f7d2e1;
$color-gray: #666666;
$color-lightgray: #999999;
/*-----------------------------
フォント
-----------------------------*/
.fsz16 {
  font-size: 16px;
}
.fsz18 {
  font-size: 18px;
}
.fsz22 {
  font-size: 22px;
}
.fsz28 {
  font-size: 28px;
}
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);

body {
    font-family: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    letter-spacing: 0.1em;
    font-size: 16px;
}
.en {
  font-family: 'EB Garamond', serif;
}
.mincho {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
.fsz32 {
  font-size: 32px;
}
$gothic: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
h2 {
position: relative;
display: inline-block;
margin-bottom: 1em;
font-size: 24px;
font-family: $mincho;
letter-spacing: 0.1em;
margin-bottom: 80px;
margin-top: 100px;
text-align: center;
color: $color-pink;
}
h2:before {
content: '';
position: absolute;
bottom: -25px;
display: inline-block;
width: 60px;
height: 5px;
left: 50%;
transform: translateX(-50%);
background-color: $color-pink;
border-radius: 2px;
}
@media (max-width: 640px) {
.br {
  display: none;
}
}
h3 {
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2rem;
  color: $color-pink;
  line-height: 3em;
  margin-bottom: 50px;
}
@media (max-width: 640px) {
  h3 {
    font-size: 18px;
    line-height: 2rem;
  }
}
/*-----------------------------
デバイス対応
-----------------------------*/
.sp {
  display: none;
}
.tab {
  display: none;
}
.pc {
  display: block;
}
@media (max-width: 640px) {
  .sp {
    display: block;
  }
  .tab {
    display: none;
  }
}
@media (max-width: 1080px) {
  .pc {
    display: none;
  }
  .tab {
    display: block;
  }
}
/*-----------------------------
レイアウト
-----------------------------*/
.container {
  max-width: 1080px;
  margin: 0 auto;
}
@media (max-width: 1080px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
  .column .container {
    width: 100%;
  }
}
.hero {
  width: 100%;
  height: 320px;
  position: relative;
  .hero_title h3 {
      padding-top: 150px;
      text-align: left;
  }
}
.hero_title {
  vertical-align: middle;
  font-size: 32px;
  letter-spacing: 0.1rem;
  font-family: 'EB Garamond', serif;
}
@media (max-width: 768px) {
  .hero {
    width: 100%;
    height: 30%;
    position: relative;
  }
  .hero_title {
    position: absolute;
    left: 3%;
    bottom: 2rem;
    h3 {
      padding-top: 0;
      margin-bottom: 0;
    }
  }
}
/*-----------------------------
パーツ
-----------------------------*/
.btn {
  margin-top: 2rem;
}
.btn a {
  display: inline-block;
  margin: 0 auto;
  line-height: 1.8rem;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-left: 2px solid $color-pink;
  border-bottom: 2px solid $color-pink;
  text-decoration: none;
  color: $color-pink;
  transition: 0.3s;
}

.btn a:hover {
  background: $color-hover;
  border: 2px solid $color-pink;
}
@media (max-width: 768px) {
  .btn a {
    border: 1px solid $color-pink;
    background:  $color-hover;
    &:hover {
      border: 1px solid $color-pink;
    }
  }
}
