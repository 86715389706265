.menu-trigger,
.menu-trigger span{
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  cursor :pointer;
}
.menu-trigger{
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 2;
}
.menu-trigger span{
  position: absolute;
  background: $color-pink;
  width: 30px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
}
.menu-trigger span:nth-of-type(1){
  top: 15px;
}
.menu-trigger span:nth-of-type(2){
  top: 0;
  bottom: 0;
}
.menu-trigger span:nth-of-type(3){
  bottom: 15px;
}
.menu-trigger.active span:nth-of-type(1){
  -webkit-transform: translateY(9px) rotate(-45deg);
	transform: translateY(9px) rotate(-45deg);
  background: $color-pink;
}
.menu-trigger.active span:nth-of-type(2){
  opacity: 0;
}
.menu-trigger.active span:nth-of-type(3){
  -webkit-transform: translateY(-9px) rotate(45deg);
	transform: translateY(-9px) rotate(45deg);
    background: $color-pink;
}
.g-nav{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.8);
  width: 100%;
  height: 100vh;
}
.g-nav .toggle-list{
  padding-top: 4rem;
}
.g-nav .toggle-list .toggle-item{
  border-bottom: 1px solid #eee;
}
.g-nav .toggle-list .toggle-item a{
  display: block;
  text-align: left;
  margin-left: 20%;
  text-decoration: none;
  color: $color-pink;
}
.toggle-item a {
    font-size: 1rem;
    line-height: 2.1rem;
    letter-spacing: 0.1rem;
}
