/*-----------------------------
ヒーロー
-----------------------------*/
.voice_hero {
  background-image: url(img/voice_hero.jpg);
  background-size: 130%;
  background-position: left center;
}
/*-----------------------------
お客様の声
-----------------------------*/
.voice_title {
  text-align: center;
 h2 {
  margin-top: 0;
  }
}
.voice p {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
  strong {
    color: $color-pink;
    margin-bottom: 0.5rem;
    display: block;
  }
}
.case h4 {
  font-size: 18px;
  margin: 0 auto;
  color: $color-pink;
  border-bottom: 1px solid $color-pink;
  padding: 0.5rem 0;
  margin-bottom: 2rem;
  span {
    font-size: 36px;
    font-style: italic;
    color: #ccc;
    padding-right: 1rem;
    letter-spacing: 0.2rem;
  }
}
@media (max-width: 768px) {
  .case h4 span {
    display: block;
    padding-bottom: 1rem;
  }
}
.case ul {
    display: flex;
    justify-content: space-between;
}

.case li {
    padding: 1rem;
    border: 1px solid $color-pink;
    color: $color-pink;
    border-radius: 10px;
    margin: 0 0.5rem;
    line-height: 1.5rem;
    font-size: 14px;
    width: 33%;
    text-align: center;
}
@media (max-width: 768px) {
  .case li {
    letter-spacing: 0;
    font-size: 10px;
    line-height: 1rem;
  }
}
.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 25px 0 25px;
  border-color: $color-pink transparent transparent transparent;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
p.result {
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  line-height: 2rem;
  color: $color-pink;
  display: table;
  span {
    background: linear-gradient(transparent 50%, #ffff66 50%);
  }
}
.case2 {
  margin-top: 4rem;
}
.voice_title {
  margin-top: -100px;
  padding-top: 100px;
}
