/*-----------------------------
ヒーロー
-----------------------------*/
.concept_hero {
  background-image: url(img/concept_hero.jpg);
  background-size: cover;
  background-position: 20% 40%;
}
/*-----------------------------
コンセプト
-----------------------------*/
.concept_bg {
  width: 100%;
  height: 300px;
  background-image: url("img/concept_bg.jpg");
  background-size: cover;
  background-attachment: fixed;
}
.concept {
  text-align: center;
  p {
    font-size: 16px;
    letter-spacing: 0.3rem;
    line-height: 2.5rem;
    text-align: center;
    margin-top: 50px;
  }
  img {
    max-width: 450px;
  }
}
@media only screen and (max-width:768px) {
  .concept_bg{
  background-attachment: inherit;
  background-position: center;
  }
}
.concept_contents_title {
    text-align: center;
}
.concept_contents_title h2 {
  margin-top: 0;
}
.concept_list {
  position: relative;
  h4 {
    font-size: 20px;
    color: $color-pink;
    letter-spacing: 0.2rem;
    margin-bottom: 1rem;
    span {
      font-size: 3.5rem;
      font-style: italic;
      margin-right: 1rem;
    }
  }
  p {
    margin-left: 4.5rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
  }
  li {
    margin-top: 50px;
  }
  li:nth-child(2) {
    margin-left: 320px;
  }
  li:nth-child(3) {
    margin-left: 600px;
  }
  .concept_bg2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: auto;
  }
  .concept_bg3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: auto;
  }
}
@media only screen and (max-width:1080px) {
  .concept_contents .container {
    width: 768px;
  }
  .concept_list {
    li:nth-child(2) {
      margin-left: 200px;
    }
    li:nth-child(3) {
      margin-left: 290px;
    }
    .concept_bg2 {
      width: 200px;
      height: auto;
    }
    .concept_bg3 {
      width: 200px;
      height: auto;
    }
  }
}
@media only screen and (max-width:768px) {
  .concept_contents .container {
    width: 90%;
  }
    .concept_list {
      margin: 0 auto;
      li:nth-child(2) {
        margin-left: 0;
      }
      li:nth-child(3) {
        margin-left: 0;
      }
    }
}
@media only screen and (max-width:640px) {
  .concept_list h4 {
    line-height: 2.3rem;
    span {
      display: block;
      padding-bottom: 1rem;
    }
  }
  .concept_list li p {
    margin-left: 0;
    font-size: 14px;
    letter-spacing: 0;
  }
  .concept_list {
    text-align: center;
    .concept_bg2 {
      position: static;
      text-align: center;
      margin-top: 2rem;
    }
    .concept_bg3 {
      position: static;
      text-align: center;
      margin-top: 2rem;
    }
  }
  .concept {
    img {
      max-width: 90%;
    }
    p {
      font-size: 14px;
      letter-spacing: 0.1rem;
      line-height: 1.8rem;
      text-align: center;
      margin-top: 50px;
    }
  }
}
