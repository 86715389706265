/*-----------------------------
ヒーロー
-----------------------------*/
.contact_hero {
  background-image: url(img/contact_hero.jpg);
  background-size: cover;
  background-position: 80% 80%;
}
/*-----------------------------
Q&A
-----------------------------*/
.contact_title {
  text-align: center;
 h2 {
  margin-top: 0;
  }
}
.contact dl {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
}
.qa dt {
   position: relative;
   border: 1px solid $color-pink;
   padding: 0.5rem 1rem;
   color: $color-pink;
   margin-top: 2rem;
}

.qa dt:after {
   content: "";
   position: absolute;
   border-right: 1px solid $color-pink;
   border-bottom: 1px solid $color-pink;
   width: 15px;
   right: 12px;
   top: 0.5rem;
   height: 15px;
   transform: rotate(45deg);
   transition: 0.3s;
}

.qa dt.ac:after {
   content: "";
   position: absolute;
   top: 1rem;
   transform: rotate(225deg);
}
.qa dt:nth-child(1) {
  margin-top: 0;
}
.qa dd {
  padding: 1rem;
  background: #eee;
}
@media (max-width: 1080px) {
  .qa dt:after {
     right: 22px;
     top: 1.5rem;
  }

  .qa dt.ac:after {
     content: "";
     position: absolute;
     top: 2rem;
     transform: rotate(225deg);
  }
}
/*-----------------------------
お問い合わせフォーム
-----------------------------*/
.contactform {
    tbody {
        padding-bottom: 1rem;
    }
}
.contactform tr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.contactform th {
    text-align: left;
    width: 35%;
    line-height: 1.5rem;
    vertical-align: middle;
    padding-top: 1rem;
    padding-left: 1rem;
}
.contactform td {
  padding-top: 1rem;
}
.contactform input[type="text"],
.contactform input[type="email"],
.contactform input[type="tel"],
.contactform textarea,
{
    line-height: 1.5rem;
    border: 1px solid $color-pink;
    width: 98%;
    background-color: #eee;
}
.contactform input[type="checkbox"] {
  border: 1px solid $color-pink;
  background-color: #eee;
}
.wpcf7-list-item {
  padding-bottom: 0.5rem;
}
.submit {
  margin: 0 auto;
  width: 30%;
  text-align: center;
}
.submit input[type="submit"]{
  padding: 0.5rem 2rem;
  border: 1px solid $color-pink;
  border-radius: 5px;
  font-size: 16px;
  letter-spacing: 0.1rem;
  text-align: center;
  display: inline-block;
  background: none;
  margin-top: 1rem;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    background: $color-hover;
    color: #fff;
  }
}
@media (max-width: 768px) {
  .contactform {
    margin-bottom: 100px;
  }
  .contact_text {
    margin-top: 100px;
  }
}
@media (max-width: 640px) {
  .contactform th {
    width: 100%;
    display: block;
    padding-left: 0;
  }
  .contactform td {
    width: 100%;
    display: block;
    padding-top: 0;
  }
}
.contact_title {
  margin-top: -100px;
  padding-top: 100px;
}
