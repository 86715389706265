.footer {
    padding: 2rem 0;
    text-align: center;
    background: $color-pink;
    small {
      color: #fff;
      font-size: 13px;
    }
}

.footer img {width: 83px;margin-bottom: 2rem;}

.footer_nav_list {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
    .li2 {
      margin-bottom: 1rem;
    }
}

.footer_nav_list li a {
    padding: 0.5rem;
    display: block;
    text-decoration: none;
    color:#fff;
    text-align: left;
    font-size: 13px;

    &:hover {
      color: $color-hover;
    }
}
@media (max-width: 960px){
  .footer_nav_list li a:after {
    content: "";
    padding-left: 0;
  }
  .footer_nav_list li:first-child a:before {
      content: "";
      padding-right: 0;
  }
}
@media (max-width: 640px){
  .footer .container {
    width: 100%;
  }
  .footer_nav_list {
      letter-spacing: 0;
  }
  .footer_nav_list li a {
      padding: 0.4rem;
    }
}
/*moveエフェクト*/
.move{
	opacity: 0;
	transform: translate(0,60px);
  transition: 1s;
}
.move_on{
	opacity: 1.0;
	transform: translate(0,0);
}
.move2{
	opacity: 0;
	transform: translate(60px,0px);
  transition: 1s;
}
.move_on2{
	opacity: 1.0;
	transform: translate(0,0);
}
.move3{
	opacity: 0;
	transform: translate(-60px,0px);
  transition: 1s;
}
.move_on3{
	opacity: 1.0;
	transform: translate(0,0);
}
