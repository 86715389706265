@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/*  HTML5 display-role reset for older browsers
*/
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;
}

/*-----------------------------
全体構造
-----------------------------*/
html {
  height: 100%;
}

body {
  height: 100%;
  background: #fff;
  font-size: 14px;
  color: #666;
}

* {
  box-sizing: border-box;
}

section {
  margin-top: 150px;
  margin-bottom: 150px;
}

article {
  margin-top: 150px;
  margin-bottom: 150px;
}

aside {
  margin-top: 150px;
  margin-bottom: 150px;
}

@media (max-width: 768px) {
  section {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  article {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  aside {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.column .column_left {
  width: 320px;
  height: 500px;
}

.column .column_right {
  width: 760px;
  padding: 0 0 0 4rem;
}

@media (max-width: 1080px) {
  .column {
    display: block;
  }
  .column .column_left {
    width: 100%;
    height: auto;
    padding: 0 2rem;
  }
  .column .column_right {
    width: 100%;
    padding: 0 2rem;
  }
}

@media (max-width: 640px) {
  .column .column_left {
    padding: 0;
  }
  .column .column_right {
    padding: 0;
  }
}

/*-----------------------------
カラー
-----------------------------*/
/*-----------------------------
フォント
-----------------------------*/
.fsz16 {
  font-size: 16px;
}

.fsz18 {
  font-size: 18px;
}

.fsz22 {
  font-size: 22px;
}

.fsz28 {
  font-size: 28px;
}

body {
  font-family: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  letter-spacing: 0.1em;
  font-size: 16px;
}

.en {
  font-family: 'EB Garamond', serif;
}

.mincho {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.fsz32 {
  font-size: 32px;
}

h2 {
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  font-size: 24px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  letter-spacing: 0.1em;
  margin-bottom: 80px;
  margin-top: 100px;
  text-align: center;
  color: #cc1a5e;
}

h2:before {
  content: '';
  position: absolute;
  bottom: -25px;
  display: inline-block;
  width: 60px;
  height: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #cc1a5e;
  border-radius: 2px;
}

@media (max-width: 640px) {
  .br {
    display: none;
  }
}

h3 {
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2rem;
  color: #cc1a5e;
  line-height: 3em;
  margin-bottom: 50px;
}

@media (max-width: 640px) {
  h3 {
    font-size: 18px;
    line-height: 2rem;
  }
}

/*-----------------------------
デバイス対応
-----------------------------*/
.sp {
  display: none;
}

.tab {
  display: none;
}

.pc {
  display: block;
}

@media (max-width: 640px) {
  .sp {
    display: block;
  }
  .tab {
    display: none;
  }
}

@media (max-width: 1080px) {
  .pc {
    display: none;
  }
  .tab {
    display: block;
  }
}

/*-----------------------------
レイアウト
-----------------------------*/
.container {
  max-width: 1080px;
  margin: 0 auto;
}

@media (max-width: 1080px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
  .column .container {
    width: 100%;
  }
}

.hero {
  width: 100%;
  height: 320px;
  position: relative;
}

.hero .hero_title h3 {
  padding-top: 150px;
  text-align: left;
}

.hero_title {
  vertical-align: middle;
  font-size: 32px;
  letter-spacing: 0.1rem;
  font-family: 'EB Garamond', serif;
}

@media (max-width: 768px) {
  .hero {
    width: 100%;
    height: 30%;
    position: relative;
  }
  .hero_title {
    position: absolute;
    left: 3%;
    bottom: 2rem;
  }
  .hero_title h3 {
    padding-top: 0;
    margin-bottom: 0;
  }
}

/*-----------------------------
パーツ
-----------------------------*/
.btn {
  margin-top: 2rem;
}

.btn a {
  display: inline-block;
  margin: 0 auto;
  line-height: 1.8rem;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-left: 2px solid #cc1a5e;
  border-bottom: 2px solid #cc1a5e;
  text-decoration: none;
  color: #cc1a5e;
  transition: 0.3s;
}

.btn a:hover {
  background: #f7d2e1;
  border: 2px solid #cc1a5e;
}

@media (max-width: 768px) {
  .btn a {
    border: 1px solid #cc1a5e;
    background: #f7d2e1;
  }
  .btn a:hover {
    border: 1px solid #cc1a5e;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 80px;
  transition: all 0.3s;
  border-bottom: 1px solid #cc1a5e;
}

.header_contents {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.header_contents .header_left {
  width: 20%;
}

.header_contents .header_right {
  width: 80%;
}

@media (max-width: 1080px) {
  .header_contents .header_right {
    text-align: right;
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

.header_left h1 img {
  width: 180px;
  height: auto;
  margin-left: 1rem;
  margin-top: 8px;
  margin-bottom: 8px;
}

.header_mark {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: rotateX(-50%, 0);
  z-index: 9999999;
}

.header_mark {
  position: fixed;
  top: 80px;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, 0);
  height: 30px;
}

.header_mark img {
  width: 60px;
}

/*PC用g-nav*/
/*.pc_nav_list {
  display: flex;
  justify-content: space-around;
}
.pc_nav_list {
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
}

.pc_nav_list li a {
    padding: 1rem;
    text-decoration: none;
    vertical-align: middle;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: $color-pink;
    }
}
.pc_nav_list li a.scroll {
  color: $color-gray;
}
.header.scroll2 {
  box-shadow: 0 0 5px #ccc;
}
@media (max-width: 960px) {
  .pc_nav_list {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}
.pc_nav_list li {
    line-height: 3rem;
}
@media (max-width: 768px) {
  .header_contents .pc {
    display: none;
  }
}

*/
/*-----------------------------
ドップダウンメニュー
-----------------------------*/
.dropmenu {
  zoom: 1;
  list-style-type: none;
  margin: 0 auto 30px;
  padding: 0;
}

.dropmenu:before, .dropmenu:after {
  content: "";
  display: table;
}

.dropmenu ul {
  display: flex;
  justify-content: space-around;
}

.dropmenu li {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: center;
}

.dropmenu li a {
  display: block;
  margin: 0;
  padding: 21px 15px 21px;
  color: #cc1a5e;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  font-family: 'EB Garamond', serif;
}

.dropmenu li a span {
  display: block;
  bottom: 5px;
  margin-top: 8px;
  font-size: 13px;
  font-family: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.dropmenu li ul {
  list-style: none;
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  display: block;
}

.dropmenu li ul li {
  width: 200%;
}

.dropmenu li ul li a {
  padding: 10px 15px;
  border-top: 1px solid #f7d2e1;
  background: #fff;
  text-align: left;
  font-size: 14px;
  font-family: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.dropmenu li:hover > a {
  background: #fff;
}

.dropmenu li a:hover {
  background: #f7d2e1;
  text-decoration: none;
}

#fade-in li ul {
  visibility: hidden;
  opacity: 0;
  transition: 0s;
}

#fade-in li:hover ul {
  visibility: visible;
  opacity: 1;
}

#fade-in li ul li a {
  visibility: hidden;
  opacity: 0;
  transition: .5s;
}

#fade-in li:hover ul li a {
  visibility: visible;
  opacity: 1;
}

#pageTop {
  position: fixed;
  bottom: 0;
  right: 20px;
}

#pageTop a {
  display: block;
  z-index: 99999999;
  padding: 0.5rem 1rem 0.5rem 1rem;
  height: auto;
  text-decoration: none;
  transition: 0.3s;
  background: #ccc;
  color: #fff;
  font-size: 13px;
  border-radius: 10px 10px 0 0;
}

#pageTop a:hover {
  background: #cc1a5e;
}

.fab {
  font-size: 2rem;
  padding-top: 5px;
}

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  cursor: pointer;
}

.menu-trigger {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.menu-trigger span {
  position: absolute;
  background: #cc1a5e;
  width: 30px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
}

.menu-trigger span:nth-of-type(1) {
  top: 15px;
}

.menu-trigger span:nth-of-type(2) {
  top: 0;
  bottom: 0;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 15px;
}

.menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(9px) rotate(-45deg);
  transform: translateY(9px) rotate(-45deg);
  background: #cc1a5e;
}

.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.menu-trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-9px) rotate(45deg);
  transform: translateY(-9px) rotate(45deg);
  background: #cc1a5e;
}

.g-nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100vh;
}

.g-nav .toggle-list {
  padding-top: 4rem;
}

.g-nav .toggle-list .toggle-item {
  border-bottom: 1px solid #eee;
}

.g-nav .toggle-list .toggle-item a {
  display: block;
  text-align: left;
  margin-left: 20%;
  text-decoration: none;
  color: #cc1a5e;
}

.toggle-item a {
  font-size: 1rem;
  line-height: 2.1rem;
  letter-spacing: 0.1rem;
}

/*-----------------------------
ファーストビュー
-----------------------------*/
.top_hero {
  width: 80%;
  margin: 0 auto;
  height: 500px;
  margin-top: 150px;
  background-image: url(img/top_hero.jpg);
  background-position: center center;
  background-size: cover;
  position: relative;
}

.top_firstview {
  position: absolute;
  bottom: 20%;
  left: 10%;
  z-index: 999;
}

.top_firstview .top_firstview_copy {
  color: #cc1a5e;
  text-align: center;
  font-size: 40px;
  text-shadow: 0 0 2px #fff;
  letter-spacing: 0.5rem;
  line-height: 4rem;
}

.mark1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}

.mark2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
}

.mark3 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
}

.mark4 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}

@media (max-width: 768px) {
  .top_hero {
    height: 30%;
  }
  .top_firstview .top_firstview_copy {
    font-size: 20px;
    line-height: 2rem;
  }
  .mark1, .mark2, .mark3, .mark4 {
    width: 60px;
  }
}

@media (max-width: 640px) {
  .top_firstview .top_firstview_copy {
    font-size: 24px;
  }
}

/*-----------------------------
コンセプト
-----------------------------*/
.top_concept {
  text-align: center;
}

.top_concept img {
  max-width: 450px;
}

.top_concept p {
  font-size: 14px;
  letter-spacing: 0.3rem;
  line-height: 2rem;
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 640px) {
  .top_concept img {
    max-width: 90%;
  }
  .top_concept p {
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
  }
}

/*-----------------------------
ジュエルカウンセリングとは
-----------------------------*/
.top_about {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: row-reverse;
}

.top_about_left {
  width: 50%;
}

.top_about_left h3 {
  text-align: left;
  margin-top: 4rem;
}

.top_about_right {
  position: relative;
  width: 50%;
}

.top_about_right img {
  width: 100%;
}

.top_about_right .mark2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
}

.top_about_right .mark3 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}

@media (max-width: 1080px) {
  .top_about {
    display: block;
    width: 90%;
  }
  .top_about .top_about_left, .top_about .top_about_right {
    width: 100%;
  }
  .top_about .top_about_left {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .top_about .top_about_left h3 {
    margin-top: 0;
    text-align: center;
  }
  .top_about .top_about_left .btn {
    text-align: center;
  }
  .top_about_right {
    text-align: center;
  }
  .top_about_right img {
    width: 100%;
  }
  .top_about_right .mark2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
  }
  .top_about_right .mark3 {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
  }
}

/*-----------------------------
サービス
-----------------------------*/
.top_service {
  text-align: center;
  border: 1px solid #cc1a5e;
  padding: 2rem 2rem;
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;
  background: #fff;
}

.top_service h2 {
  margin-top: 50px;
}

.top_serbice::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: -2rem;
  margin-left: 2rem;
}

@media (max-width: 768px) {
  .top_service {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.top_service ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.top_service ul li {
  position: relative;
  width: 300px;
  height: 197px;
  background-image: url(img/menu_bg.png);
  background-position: center;
  background-size: cover;
  transition: 0.3s;
}

.top_service ul li:hover {
  transform: translate(0, -20px);
  opacity: 0.7;
}

.top_service ul li a {
  display: block;
  padding-top: 60px;
  color: #cc1a5e;
  text-decoration: none;
  font-size: 24px;
  width: 100%;
  height: 100%;
  line-height: 2.4rem;
  transition: 0.3s;
}

.top_service ul li a span {
  display: block;
  font-size: 14px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.top_service:after {
  content: "";
  width: 100%;
  height: 100%;
  top: 20px;
  left: 20px;
  position: absolute;
  z-index: -1;
  margin-left: 20px;
  margin-top: 20px;
  background: #cc1a5e;
}

@media (max-width: 1080px) {
  .top_service ul li {
    width: 200px;
    height: 131px;
    margin-top: 0px;
  }
  .top_service ul li a {
    padding-top: 40px;
    line-height: 2rem;
    font-size: 1rem;
  }
  .top_service:after {
    top: 5px;
    left: 5px;
  }
}

@media (max-width: 768px) {
  .top_service ul {
    display: block;
  }
  .top_service ul li {
    width: 260px;
    height: 167px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
  .top_service ul li a {
    padding-top: 60px;
  }
  .top_service:after {
    top: 2px;
    left: 2px;
  }
}

@media (max-width: 640px) {
  .top_service:after {
    display: none;
  }
}

/*-----------------------------
ジュエルカラーズについて
-----------------------------*/
.top_prof {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 150px;
}

.top_prof_left {
  width: 50%;
}

.top_prof_right {
  width: 50%;
  text-align: center;
}

.top_prof_right h3 {
  color: #666666;
  font-size: 28px;
}

.top_prof_right img {
  width: 450px;
  margin-bottom: 50px;
}

.top_prof_right p {
  line-height: 2rem;
  letter-spacing: 0.2rem;
  text-align: left;
  font-size: 14px;
  max-width: 450px;
  margin: 0 auto;
}

.top_prof_img2 {
  margin-top: -50px;
  margin-left: 100px;
}

@media (max-width: 1080px) {
  .top_prof {
    display: block;
  }
  .top_prof .top_prof_left {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .top_prof .top_prof_left .top_prof_img1 img {
    width: 100%;
  }
  .top_prof .top_prof_left .top_prof_img2 {
    margin-top: 0;
    margin-left: 0;
  }
  .top_prof .top_prof_left .top_prof_img2 img {
    max-width: 100%;
  }
  .top_prof .top_prof_right {
    width: 100%;
    margin-top: 3rem;
  }
}

@media (max-width: 640px) {
  .top_prof_right img {
    width: 100%;
  }
  .top_prof_right p {
    max-width: 100%;
  }
  .top_prof .top_prof_left {
    display: block;
  }
}

.footer {
  padding: 2rem 0;
  text-align: center;
  background: #cc1a5e;
}

.footer small {
  color: #fff;
  font-size: 13px;
}

.footer img {
  width: 83px;
  margin-bottom: 2rem;
}

.footer_nav_list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.footer_nav_list .li2 {
  margin-bottom: 1rem;
}

.footer_nav_list li a {
  padding: 0.5rem;
  display: block;
  text-decoration: none;
  color: #fff;
  text-align: left;
  font-size: 13px;
}

.footer_nav_list li a:hover {
  color: #f7d2e1;
}

@media (max-width: 960px) {
  .footer_nav_list li a:after {
    content: "";
    padding-left: 0;
  }
  .footer_nav_list li:first-child a:before {
    content: "";
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  .footer .container {
    width: 100%;
  }
  .footer_nav_list {
    letter-spacing: 0;
  }
  .footer_nav_list li a {
    padding: 0.4rem;
  }
}

/*moveエフェクト*/
.move {
  opacity: 0;
  transform: translate(0, 60px);
  transition: 1s;
}

.move_on {
  opacity: 1.0;
  transform: translate(0, 0);
}

.move2 {
  opacity: 0;
  transform: translate(60px, 0px);
  transition: 1s;
}

.move_on2 {
  opacity: 1.0;
  transform: translate(0, 0);
}

.move3 {
  opacity: 0;
  transform: translate(-60px, 0px);
  transition: 1s;
}

.move_on3 {
  opacity: 1.0;
  transform: translate(0, 0);
}

/*-----------------------------
ヒーロー
-----------------------------*/
.concept_hero {
  background-image: url(img/concept_hero.jpg);
  background-size: cover;
  background-position: 20% 40%;
}

/*-----------------------------
コンセプト
-----------------------------*/
.concept_bg {
  width: 100%;
  height: 300px;
  background-image: url("img/concept_bg.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.concept {
  text-align: center;
}

.concept p {
  font-size: 16px;
  letter-spacing: 0.3rem;
  line-height: 2.5rem;
  text-align: center;
  margin-top: 50px;
}

.concept img {
  max-width: 450px;
}

@media only screen and (max-width: 768px) {
  .concept_bg {
    background-attachment: inherit;
    background-position: center;
  }
}

.concept_contents_title {
  text-align: center;
}

.concept_contents_title h2 {
  margin-top: 0;
}

.concept_list {
  position: relative;
}

.concept_list h4 {
  font-size: 20px;
  color: #cc1a5e;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
}

.concept_list h4 span {
  font-size: 3.5rem;
  font-style: italic;
  margin-right: 1rem;
}

.concept_list p {
  margin-left: 4.5rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
}

.concept_list li {
  margin-top: 50px;
}

.concept_list li:nth-child(2) {
  margin-left: 320px;
}

.concept_list li:nth-child(3) {
  margin-left: 600px;
}

.concept_list .concept_bg2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: auto;
}

.concept_list .concept_bg3 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300px;
  height: auto;
}

@media only screen and (max-width: 1080px) {
  .concept_contents .container {
    width: 768px;
  }
  .concept_list li:nth-child(2) {
    margin-left: 200px;
  }
  .concept_list li:nth-child(3) {
    margin-left: 290px;
  }
  .concept_list .concept_bg2 {
    width: 200px;
    height: auto;
  }
  .concept_list .concept_bg3 {
    width: 200px;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .concept_contents .container {
    width: 90%;
  }
  .concept_list {
    margin: 0 auto;
  }
  .concept_list li:nth-child(2) {
    margin-left: 0;
  }
  .concept_list li:nth-child(3) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  .concept_list h4 {
    line-height: 2.3rem;
  }
  .concept_list h4 span {
    display: block;
    padding-bottom: 1rem;
  }
  .concept_list li p {
    margin-left: 0;
    font-size: 14px;
    letter-spacing: 0;
  }
  .concept_list {
    text-align: center;
  }
  .concept_list .concept_bg2 {
    position: static;
    text-align: center;
    margin-top: 2rem;
  }
  .concept_list .concept_bg3 {
    position: static;
    text-align: center;
    margin-top: 2rem;
  }
  .concept img {
    max-width: 90%;
  }
  .concept p {
    font-size: 14px;
    letter-spacing: 0.1rem;
    line-height: 1.8rem;
    text-align: center;
    margin-top: 50px;
  }
}

/*-----------------------------
ヒーロー
-----------------------------*/
.service_hero {
  background-image: url(img/service_hero.jpg);
  background-size: 130%;
  background-position: left center;
}

/*-----------------------------
サービス
-----------------------------*/
.service_title {
  text-align: center;
}

.service_title h2 {
  margin-top: 0;
}

.service p {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
}

.service table {
  border: 1px solid #cc1a5e;
  margin: 0 auto;
}

.service th {
  padding: 1rem;
  border: 1px solid #cc1a5e;
  vertical-align: middle;
  line-height: 1.5rem;
  color: #cc1a5e;
}

@media (max-width: 640px) {
  .service th {
    width: 30%;
  }
}

.service td {
  padding: 1rem;
  border: 1px solid #cc1a5e;
  line-height: 1.5rem;
  color: #cc1a5e;
}

.service td a {
  color: #cc1a5e;
}

.service td a:hover {
  color: #f7d2e1;
}

.service h4 {
  margin: 0 auto;
  background: #cc1a5e;
  color: #fff;
  border-radius: 100px;
  padding: 0.5rem 2rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: table;
}

.service_aod_link {
  display: table;
  border: 1px solid #cc1a5e;
  text-align: center;
  margin: 0 auto;
}

.service_aod_link a {
  color: #cc1a5e;
  text-decoration: none;
  font-size: 14px;
  padding: 1rem;
  display: block;
}

.service_aod_link a:hover {
  opacity: 0.7;
}

.service_aod_link p {
  margin: 0;
}

.service_aod_link img {
  width: 150px;
}

/*-----------------------------
asideメニュー
-----------------------------*/
.column_left h5 {
  letter-spacing: 0.2rem;
  padding: 1rem 0 1rem 1rem;
  border-bottom: 1px solid #cc1a5e;
  color: #fff;
  font-size: 20px;
  background: #cc1a5e;
  border-radius: 10px 10px 0 0;
}

.column_left li {
  border-bottom: 1px solid #cc1a5e;
}

.column_left li a {
  color: #cc1a5e;
  text-decoration: none;
  display: block;
  padding: 1rem;
  transition: 0.3s;
}

.column_left li a:hover {
  background: #cc1a5e;
  color: #fff;
  text-decoration: none;
}

.ko_menu li {
  border: none;
}

.ko_menu li a {
  padding: 1rem;
  padding-left: 1rem;
  transition: 0.3s;
}

.ko_menu li a:nth-child(2) {
  padding: 0;
  padding-left: 1rem;
}

.scroll_menu {
  height: 300px;
}

@media (max-width: 1080px) {
  .scroll_menu {
    height: auto;
  }
}

.fixed_not {
  position: relative;
  top: 0;
}

.fixed {
  position: fixed;
  top: 0;
  width: 320px;
}

.fixed_releace {
  position: absolute;
  bottom: 535px;
}

@media (max-width: 1080px) {
  .fixed_not {
    position: inherit;
  }
  .fixed {
    position: inherit;
    width: 100%;
  }
  .fixed_releace {
    position: inherit;
  }
}

.service_title {
  margin-top: -100px;
  padding-top: 100px;
}

/*-----------------------------
ヒーロー
-----------------------------*/
.voice_hero {
  background-image: url(img/voice_hero.jpg);
  background-size: 130%;
  background-position: left center;
}

/*-----------------------------
お客様の声
-----------------------------*/
.voice_title {
  text-align: center;
}

.voice_title h2 {
  margin-top: 0;
}

.voice p {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
}

.voice p strong {
  color: #cc1a5e;
  margin-bottom: 0.5rem;
  display: block;
}

.case h4 {
  font-size: 18px;
  margin: 0 auto;
  color: #cc1a5e;
  border-bottom: 1px solid #cc1a5e;
  padding: 0.5rem 0;
  margin-bottom: 2rem;
}

.case h4 span {
  font-size: 36px;
  font-style: italic;
  color: #ccc;
  padding-right: 1rem;
  letter-spacing: 0.2rem;
}

@media (max-width: 768px) {
  .case h4 span {
    display: block;
    padding-bottom: 1rem;
  }
}

.case ul {
  display: flex;
  justify-content: space-between;
}

.case li {
  padding: 1rem;
  border: 1px solid #cc1a5e;
  color: #cc1a5e;
  border-radius: 10px;
  margin: 0 0.5rem;
  line-height: 1.5rem;
  font-size: 14px;
  width: 33%;
  text-align: center;
}

@media (max-width: 768px) {
  .case li {
    letter-spacing: 0;
    font-size: 10px;
    line-height: 1rem;
  }
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 25px 0 25px;
  border-color: #cc1a5e transparent transparent transparent;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

p.result {
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  line-height: 2rem;
  color: #cc1a5e;
  display: table;
}

p.result span {
  background: linear-gradient(transparent 50%, #ffff66 50%);
}

.case2 {
  margin-top: 4rem;
}

.voice_title {
  margin-top: -100px;
  padding-top: 100px;
}

/*-----------------------------
ヒーロー
-----------------------------*/
.flow_hero {
  background-image: url(img/flow_hero.jpg);
  background-size: cover;
  background-position: center;
}

/*-----------------------------
ステップ
-----------------------------*/
/*-----------------------------
ヒーロー
-----------------------------*/
.service_hero {
  background-image: url(img/profile_hero.jpg);
  background-size: 130%;
  background-position: left center;
}

/*-----------------------------
お客様の声
-----------------------------*/
.profile_title {
  text-align: center;
}

.profile_title h2 {
  margin-top: 0;
}

.profile p {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
}

.profile p strong {
  color: #cc1a5e;
  margin-bottom: 0.5rem;
  display: block;
}

.profile img {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}

.profile dt {
  float: left;
  clear: left;
  width: 20%;
  line-height: 2.5rem;
}

.profile dd {
  padding-left: 20%;
  border-bottom: 1px solid #999999;
  line-height: 2.5rem;
}

.profile .no-border {
  border-bottom: none;
}

@media (max-width: 640px) {
  .profile dt, .profile dd {
    letter-spacing: 0;
    line-height: 2rem;
  }
}

.profile_title {
  margin-top: -100px;
  padding-top: 100px;
}

/*-----------------------------
ヒーロー
-----------------------------*/
.blog_hero {
  background-image: url(img/blog_hero.jpg);
  background-size: cover;
  background-position: 20% 20%;
}

/*-----------------------------
作品の最新一覧
-----------------------------*/
.archive_layout {
  margin-bottom: 150px;
}

.archive {
  margin-top: 150px;
}

.archive_list {
  border-bottom: 1px solid #cc1a5e;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .archive {
    margin-top: 100px;
  }
}

.archive a {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  text-decoration: none;
}

.archive a:hover {
  text-decoration: underline;
}

.archive_kiji_title {
  font-size: 1.3rem;
}

.archive_list a {
  color: #cc1a5e;
}

.archive_cat a {
  width: 200px;
  text-align: center;
  padding: 0 1rem;
  font-size: 12px;
  background: #ccc;
  display: inline-block;
  color: #fff;
  border-radius: 100px;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.archive_cat a:hover {
  background: #f7d2e1;
  text-decoration: none;
}

.archive_time {
  display: inline;
}

/*-----------------------------
ページネーション
-----------------------------*/
.page-area {
  position: relative;
  width: 100%;
  height: 2rem;
}

.pagination {
  color: #cc1a5e;
  line-height: 2em;
  text-align: center;
  clear: both;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.pagination a {
  text-decoration: none;
  padding: 0.25rem 0.7rem;
  margin-left: 0.5rem;
  border: 1px solid #cc1a5e;
  color: #cc1a5e;
}

.pagination a:hover {
  background: #cc1a5e;
  color: #fff;
}

.pagination span {
  padding: 0.25rem 0.7rem;
  margin-left: 0.5rem;
  border: 1px solid #cc1a5e;
  color: #fff;
  background-color: #cc1a5e;
}

/*-----------------------------
サイドバー
-----------------------------*/
.sidebar_category {
  list-style: none;
}

/*-----------------------------
ヒーロー
-----------------------------*/
.profile_hero {
  background-image: url(img/archive_hero.jpg);
  background-size: cover;
  background-position: center;
}

/*-----------------------------
記事の写真
-----------------------------*/
.single_kiji {
  width: 70%;
  margin: 0 auto;
}

.single_kiji img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.single_contents {
  margin-bottom: 2rem;
}

/*-----------------------------
ページネーション
-----------------------------*/
.post-navigation {
  display: flex;
  justify-content: space-between;
}

.post-navigation a {
  text-decoration: none;
  color: #666666;
}

.post-navigation a:hover {
  color: #cc1a5e;
}

/*-----------------------------
本文
-----------------------------*/
div.single_contents p {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.8rem;
}

div.single_contents h1 {
  font-size: 24px;
  letter-spacing: 0.1rem;
  line-height: 3rem;
}

div.single_contents h2 {
  margin: 0;
  color: inherit;
  font-size: 22px;
  font-family: inherit;
  letter-spacing: 0.1rem;
  line-height: 2.8rem;
}

div.single_contents h2:before {
  display: none;
  content: "";
}

div.single_contents h3 {
  font-size: 20px;
  font-family: inherit;
  letter-spacing: 0.1rem;
  line-height: 2.4rem;
  color: inherit;
  text-align: inherit;
  margin: 0;
}

div.single_contents h4 {
  font-size: 18px;
  line-height: 2.2rem;
  letter-spacing: 0.1rem;
}

div.single_contents h5 {
  font-size: 16px;
  line-height: 2rem;
  letter-spacing: 0.1rem;
}

div.single_contents h6 {
  font-size: 14px;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
}

div.single_contents strong {
  font-weight: bold;
}

div.single_contents em {
  font-style: italic;
}

div.single_contents li {
  margin: 4px 0;
}

div.single_contents li:before {
  content: "・";
}

/*-----------------------------
ヒーロー
-----------------------------*/
.contact_hero {
  background-image: url(img/contact_hero.jpg);
  background-size: cover;
  background-position: 80% 80%;
}

/*-----------------------------
Q&A
-----------------------------*/
.contact_title {
  text-align: center;
}

.contact_title h2 {
  margin-top: 0;
}

.contact dl {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
}

.qa dt {
  position: relative;
  border: 1px solid #cc1a5e;
  padding: 0.5rem 1rem;
  color: #cc1a5e;
  margin-top: 2rem;
}

.qa dt:after {
  content: "";
  position: absolute;
  border-right: 1px solid #cc1a5e;
  border-bottom: 1px solid #cc1a5e;
  width: 15px;
  right: 12px;
  top: 0.5rem;
  height: 15px;
  transform: rotate(45deg);
  transition: 0.3s;
}

.qa dt.ac:after {
  content: "";
  position: absolute;
  top: 1rem;
  transform: rotate(225deg);
}

.qa dt:nth-child(1) {
  margin-top: 0;
}

.qa dd {
  padding: 1rem;
  background: #eee;
}

@media (max-width: 1080px) {
  .qa dt:after {
    right: 22px;
    top: 1.5rem;
  }
  .qa dt.ac:after {
    content: "";
    position: absolute;
    top: 2rem;
    transform: rotate(225deg);
  }
}

/*-----------------------------
お問い合わせフォーム
-----------------------------*/
.contactform tbody {
  padding-bottom: 1rem;
}

.contactform tr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contactform th {
  text-align: left;
  width: 35%;
  line-height: 1.5rem;
  vertical-align: middle;
  padding-top: 1rem;
  padding-left: 1rem;
}

.contactform td {
  padding-top: 1rem;
}

.contactform input[type="text"],
.contactform input[type="email"],
.contactform input[type="tel"],
.contactform textarea {
  line-height: 1.5rem;
  border: 1px solid #cc1a5e;
  width: 98%;
  background-color: #eee;
}

.contactform input[type="checkbox"] {
  border: 1px solid #cc1a5e;
  background-color: #eee;
}

.wpcf7-list-item {
  padding-bottom: 0.5rem;
}

.submit {
  margin: 0 auto;
  width: 30%;
  text-align: center;
}

.submit input[type="submit"] {
  padding: 0.5rem 2rem;
  border: 1px solid #cc1a5e;
  border-radius: 5px;
  font-size: 16px;
  letter-spacing: 0.1rem;
  text-align: center;
  display: inline-block;
  background: none;
  margin-top: 1rem;
  transition: 0.3s;
}

.submit input[type="submit"]:hover {
  cursor: pointer;
  background: #f7d2e1;
  color: #fff;
}

@media (max-width: 768px) {
  .contactform {
    margin-bottom: 100px;
  }
  .contact_text {
    margin-top: 100px;
  }
}

@media (max-width: 640px) {
  .contactform th {
    width: 100%;
    display: block;
    padding-left: 0;
  }
  .contactform td {
    width: 100%;
    display: block;
    padding-top: 0;
  }
}

.contact_title {
  margin-top: -100px;
  padding-top: 100px;
}
