/*-----------------------------
ヒーロー
-----------------------------*/
.service_hero {
  background-image: url(img/profile_hero.jpg);
  background-size: 130%;
  background-position: left center;
}
/*-----------------------------
お客様の声
-----------------------------*/
.profile_title {
  text-align: center;
 h2 {
  margin-top: 0;
  }
}
.profile p {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
}
.profile p strong {
  color: $color-pink;
  margin-bottom: 0.5rem;
  display: block;
}
.profile img {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}
.profile {
  dt {
    float: left;
    clear: left;
    width: 20%;
    line-height: 2.5rem;
  }
  dd {
    padding-left: 20%;
    border-bottom: 1px solid $color-lightgray;
    line-height: 2.5rem;
  }
}
.profile .no-border {
  border-bottom: none;
}
@media (max-width: 640px){
  .profile dt, .profile dd {
    letter-spacing: 0;
    line-height: 2rem;
  }
}
.profile_title {
  margin-top: -100px;
  padding-top: 100px;
}
