.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 80px;
  transition: all 0.3s;
  border-bottom: 1px solid $color-pink;
}
.header_contents {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .header_left {
    width: 20%;
  }
  .header_right {
    width: 80%;
  }
}
@media (max-width: 1080px) {
  .header_contents {
    .header_right {
      text-align: right;
      margin-top: 1rem;
      margin-right: 1rem;
    }
  }
}
.header_left h1 img {
  width: 180px;
  height: auto;
  margin-left: 1rem;
  margin-top: 8px;
  margin-bottom: 8px;
}
.header_mark {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: rotateX(-50%, 0);
  z-index: 9999999;
}
.header_mark {
    position: fixed;
    top: 80px;
    left: 50%;
    z-index: 9;
    transform: translate(-50%,0);
    height: 30px;
}

.header_mark img {
    width: 60px;
}
/*PC用g-nav*/
/*.pc_nav_list {
  display: flex;
  justify-content: space-around;
}
.pc_nav_list {
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
}

.pc_nav_list li a {
    padding: 1rem;
    text-decoration: none;
    vertical-align: middle;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: $color-pink;
    }
}
.pc_nav_list li a.scroll {
  color: $color-gray;
}
.header.scroll2 {
  box-shadow: 0 0 5px #ccc;
}
@media (max-width: 960px) {
  .pc_nav_list {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}
.pc_nav_list li {
    line-height: 3rem;
}
@media (max-width: 768px) {
  .header_contents .pc {
    display: none;
  }
}

*/
/*-----------------------------
ドップダウンメニュー
-----------------------------*/
.dropmenu{
  zoom: 1;
  list-style-type: none;
  margin: 0 auto 30px;
  padding: 0;
}
.dropmenu:before, .dropmenu:after{
  content: "";
  display: table;
}
.dropmenu ul {
    display: flex;
    justify-content: space-around;
}
.dropmenu li{
  position: relative;
  margin: 0;
  padding: 0;
  text-align: center;
}
.dropmenu li a{
  display: block;
  margin: 0;
  padding: 21px 15px 21px;
  color: $color-pink;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  font-family: 'EB Garamond', serif;
}
.dropmenu li a span {
    display: block;
    bottom: 5px;
    margin-top: 8px;
    font-size: 13px;
    font-family: $gothic;
}
.dropmenu li ul{
  list-style: none;
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  display: block;
}
.dropmenu li ul li{
  width: 200%;
}
.dropmenu li ul li a{
  padding: 10px 15px;
  border-top: 1px solid $color-hover;
  background: #fff;
  text-align: left;
  font-size: 14px;
  font-family: $gothic;
}
.dropmenu li:hover > a{
  background: #fff;
}
.dropmenu li a:hover{
  background: #f7d2e1;
  text-decoration: none;
}
#fade-in li ul{
  visibility: hidden;
  opacity: 0;
  transition: 0s;
}
#fade-in li:hover ul{
  visibility: visible;
  opacity: 1;
}
#fade-in li ul li a{
  visibility: hidden;
  opacity: 0;
  transition: .5s;
}
#fade-in li:hover ul li a{
  visibility: visible;
  opacity: 1;
}


#pageTop {

  position: fixed;
  bottom: 0;
  right: 20px;
}
#pageTop a {
  display: block;
  z-index: 99999999;
  padding: 0.5rem 1rem 0.5rem 1rem;
  height: auto;
  text-decoration: none;
  transition: 0.3s;
  background: #ccc;
  color: #fff;
  font-size: 13px;
  border-radius: 10px 10px 0 0;
}
#pageTop a:hover {
  background: $color-pink;
}
.fab {
  font-size: 2rem;
  padding-top: 5px;
}
