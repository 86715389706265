/*-----------------------------
ヒーロー
-----------------------------*/
.service_hero {
  background-image: url(img/service_hero.jpg);
  background-size: 130%;
  background-position: left center;
}
/*-----------------------------
サービス
-----------------------------*/
.service_title {
  text-align: center;
 h2 {
  margin-top: 0;
  }
}
.service p {
  line-height: 2rem;
  letter-spacing: 0.1rem;
  font-size: 16px;
  margin-bottom: 3rem;
}
.service table {
  border: 1px solid $color-pink;
  margin: 0 auto;
}
.service th {
  padding: 1rem;
  border: 1px solid $color-pink;
  vertical-align: middle;
  line-height: 1.5rem;
  color: $color-pink;
}
@media (max-width: 640px) {
  .service th {
    width: 30%;
  }
}
.service td {
  padding: 1rem;
  border: 1px solid $color-pink;
  line-height: 1.5rem;
  color: $color-pink;
  a {
    color: $color-pink;
    &:hover {
      color: $color-hover;
    }
  }
}
.service h4 {
  margin: 0 auto;
  background: $color-pink;
  color: #fff;
  border-radius: 100px;
  padding: 0.5rem 2rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: table;
}
.service_aod_link {
  display: table;
  border: 1px solid $color-pink;
  text-align: center;
  margin: 0 auto;
  a {
    color: $color-pink;
    text-decoration: none;
    font-size: 14px;
    padding:1rem;
    display: block;
    &:hover {
      opacity: 0.7;
    }
  }
  p {
    margin: 0;
  }
  img {
    width: 150px;
  }
}
/*-----------------------------
asideメニュー
-----------------------------*/
.column_left {
  h5 {
    letter-spacing: 0.2rem;
    padding:  1rem 0 1rem 1rem;
    border-bottom: 1px solid $color-pink;
    color: #fff;
    font-size: 20px;
    background: $color-pink;
    border-radius: 10px 10px 0 0;
  }
  li {
    border-bottom: 1px solid $color-pink;
    a {
      color: $color-pink;
      text-decoration: none;
      display: block;
      padding: 1rem;
      transition: 0.3s;
      &:hover {
        background: $color-pink;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
.ko_menu li {
    border: none;
    a {
      padding: 1rem ;
      padding-left: 1rem;
      transition: 0.3s;
    }
}
.ko_menu li a:nth-child(2) {
  padding: 0;
  padding-left: 1rem;
}
.scroll_menu{
  height: 300px;
}

@media (max-width: 1080px){
.scroll_menu {
  height: auto;
}
}
.fixed_not{
  position: relative;
  top: 0;
}
.fixed{
  position: fixed;
  top: 0;
  width: 320px;
}
.fixed_releace{
  position: absolute;
  bottom: 535px;
}
@media (max-width: 1080px){
  .fixed_not {
    position: inherit;
  }
  .fixed {
    position:inherit;
    width: 100%;
  }
  .fixed_releace {
    position: inherit;
  }
}
.service_title {
  margin-top: -100px;
  padding-top: 100px;
}
